import NextLink from 'next/link';

import Button from 'shopper/components/Button';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';

import LINKS from 'constants/links';
import TOPBAR from 'constants/topbar';

const onClick = () => {
  sendEvent({
    action: 'topbar_suggest_offer',
    category: TOPBAR.GA_EVENT_CATEGORY,
  });
};

const SuggestNewOfferButton = () => (
  <NextLink href={LINKS.NEW_OFFER} prefetch={false} passHref>
    <Button
      as="a"
      className="w-[177px]"
      data-test-selector="suggest-new-offer"
      size="size3"
      onClick={onClick}
    >
      {placeholder('actions.suggestOffer')}
    </Button>
  </NextLink>
);

export default SuggestNewOfferButton;
